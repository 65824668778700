import React from "react";
import MainLayout from "../components/MainLayout/MainLayout";
import Contact from "../components/Contact/Contact";

const contact = () => {
	return (
		<MainLayout lang="hu" title="rules">
			<Contact />
		</MainLayout>
	);
};

export default contact;
