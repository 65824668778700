import React from "react";
import { Highlight } from "../Highlight/Highlight.styles";
import Panel from "../Panel/Panel";
import * as S from "./Contact.styles";
import {
	faEnvelope,
	faLocationDot,
	faSquarePhone,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faFacebookSquare,
	faInstagram,
} from "@fortawesome/free-brands-svg-icons";
import BusinessDetails from "../../data/business.json";
import useLang from "../../hooks/useLang";

const Contact = () => {
	const [lang] = useLang();

	return (
		<S.Container>
			<S.PanelContainer>
				<Panel title={lang.contact.contact}>
					<div>
						<Highlight bold>
							<FontAwesomeIcon icon={faSquarePhone} fixedWidth />
							{lang.contact.phone}:
						</Highlight>
						<a href={`tel:${BusinessDetails.tel}`}>{BusinessDetails.tel}</a>
					</div>
					<S.Description>{lang.contact.phoneDesc}</S.Description>
					<div>
						<Highlight bold>
							<FontAwesomeIcon icon={faEnvelope} fixedWidth />
							{lang.contact.email}:
						</Highlight>
						<a href={`mailto:${BusinessDetails.email}`}>
							{BusinessDetails.email}
						</a>
					</div>
					<S.Description>{lang.contact.emailDesc}</S.Description>
					<div>
						<Highlight bold>
							<FontAwesomeIcon icon={faFacebookSquare} fixedWidth />
							{lang.contact.facebook}:
						</Highlight>
						<a href={BusinessDetails.facebook} target="_blank">
							{lang.contact.facebookLinkText}
						</a>
					</div>
					<S.Description>{lang.contact.facebookDesc}</S.Description>
					<div>
						<Highlight bold>
							<FontAwesomeIcon icon={faInstagram} fixedWidth />
							{lang.contact.instagram}:
						</Highlight>
						<a href={BusinessDetails.instagram} target="_blank">
							@leila_csermak
						</a>
					</div>
					<S.Description>{lang.contact.instagramDesc}</S.Description>
					<div>
						<Highlight bold>
							<FontAwesomeIcon icon={faLocationDot} fixedWidth />
							{lang.contact.address}:
						</Highlight>
						<a
							href="https://www.google.com/maps/place/Darabos+%C3%89vi+K%C3%B6r%C3%B6mszalon/@47.5065284,19.0603178,15z/data=!4m5!3m4!1s0x0:0x7dc598cff5f0f85d!8m2!3d47.5065284!4d19.0603178"
							target="_blank"
						>
							{BusinessDetails.address}
						</a>
					</div>
					<S.Description>{lang.contact.addressDesc}</S.Description>
				</Panel>
			</S.PanelContainer>
		</S.Container>
	);
};

export default Contact;
