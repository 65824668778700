import styled from "styled-components";

export const Container = styled.div`
	display: flex;
`;

export const PanelContainer = styled.div`
	display: flex;
	flex: 1;

	svg {
		margin-right: 0.25rem;
	}

	a {
		margin-left: 0.25rem;
	}
`;

export const Description = styled.div`
	font-size: 0.9375rem;
	display: flex;
	text-align: justify;
	font-style: italic;
	margin-bottom: 1.5rem;
	font-weight: normal;
`;
